<template>
  <div class="outter">
    <div class="ad_banner">
      <!-- <div>
        <p>公司概况</p>
        <div class="line"></div>
        <span>医药物资协会商保分会会长单位</span>
        <img src="../assets/image/page4/down.png" alt="">
      </div> -->
      <img src="../assets/image/page4/img1.png" alt="">
    </div>
    <div class="wrap about">
      <div class="container">
        <div class="title_light">
          <img src="../assets/image/page4/about.png" alt="">
        </div>
        <div class="company-name">上海聚音信息科技有限公司</div>
        <div class="line"></div>
        <p>药联健康成立于2014年，总部位于上海，在北京、南京分别设有业务中心（北京药联健康科技有限公司）和研发中心（南京云联数科科技有限公司）。截至2020年年底，拥有员工350余人，其中技术研发人员超过60%。</p>
        <p>药联健康致力推动服务化理赔方式的商业保险在零售药店及健康服务领域落地，业务产品主要包括药品直付业务和增值服务产品销售业务。药联开创性的实现了与所有合作药店及服务机构技术系统底层对接互通，前端交易在药联闭环系统中进行，实现了保障权益的规则管理和事前风控，解决了在消费场景下的<br/>
          控费合规的要求，系统具备完全自主知识产权。目前药联已和人保财险、人保寿险、太平洋保险、国寿、华夏保险等国内主要保险公司超过100个机构建立了合作。</p>
        <p>药联合作的药店覆盖全国主要省市，与国大、益丰、老百姓、一心堂、漱玉等国内百强连锁均建立了合作，截至2020年9月底合作总门店数超过10万家。</p>
        <p> 药联同时覆盖了包括爱康国宾、美年大健康、拜博口腔等体检、口腔、线上问诊服务机构，为商保用户提供更全面的药品之外健康服务。基于药联服务化理赔权益的良好体验，兴业银行、民生银行、浦发银行等银行机构以及FESCO外服等员工福利企业均开展了合作。</p>
        <div class="mission clearFix">
          <div class="mission-pic fl"><img src="../assets/image/page4/heart.png" alt=""></div>
          <div class="mission-txt fl">
            <img src="../assets/image/page4/mission.png" alt="">
            <div class="mission-txt-detail">
              <div class="mission-txt-icon circle"></div>
              <div class="mission-txt-tit">服务于民</div>
              <div class="mission-txt-word">让消费者用药更实惠、更快捷、更安全、更有效</div>
            </div>
            <div class="mission-txt-detail">
              <div class="mission-txt-icon hand"></div>
              <div class="mission-txt-tit">增值为您</div>
              <div class="mission-txt-word">为保司创造规模保费、全闭环服务化理赔有效风控</div>
              <div class="mission-txt-word">为合作药店提升竞争⼒，创造业绩增量</div>
            </div>
          </div>
        </div>
        <div class="area">
          <img src="../assets/image/page4/img4.png" alt="">
        </div>
        <div class="mark"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
@import '../assets/css/mixin.less';
.main1 {
  width: 100%;
  // height:
  min-width: 1200px;
  background: url('../assets/image/page4/img1.png') no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  > div {
    width: 1200px;
    margin: auto;
    p {
      padding-top: 223px;
      font-size: 32px;
      padding-left: 76px;
    }
    .line {
      width: 144px;
      height: 1px;
      background: #fff;
      margin: 10px 0 40px 76px;
    }
    span {
      font-size: 22px;
      display: block;
      padding-left: 76px;
    }
    img {
      display: block;
      width: 47px;
      height: 47px;
      margin-top: 80px;
      padding-bottom: 159px;
      padding-left: 76px;
    }
  }
}

.about {
  padding: 70px 0 0;

  .title_light img {
    .wh(168px, 64px);
  }

  .container {
    position: relative;
  }

  .company-name {
    .font(18px, #333);
  }

  .line {
    .wh(22px, 3px);
    margin: 15px 0 19px;
    background-color: #00B095;
  }

  p {
    .font(14px, #777);
    line-height: 26px;
    padding: 0 0 8px;
  }

  .mission {
    background-color: #00B095;
    padding: 40px 0 42px 37px;
    border-radius: 20px;
    margin: 40px 0 0;

    &-pic {
      .wh(456px, 391px);
      img {
        .wh(100%, 100%);
      }
    }

    &-txt {
      padding: 0 0 0 106px;
      color: #fff;

      &-detail {
        padding: 41px 0 39px 60px;
        box-sizing: border-box;
        position: relative;
      }

      &-icon {
        background-size: cover;
        .wh(40px, 40px);
        position: absolute;
        left: 0;
        top: 33px;

        &.circle{
          background-image: url('../assets/image/page4/icon1.png')
        }

        &.hand {
          background-image: url('../assets/image/page4/icon2.png')
        }
      }

      &-tit {
        .font(18px, #fff);
        padding: 0 0 16px;
      }

      &-word {
        .font(14px, #fff);
      }
    }
  }

  .area {
    padding: 0 0 22px;
    margin-top: -23px;

    img {
      .wh(1200px, 155px);
    }
  }

  .mark {
    .wh(68px, 57px);
    background: url('../assets/image/page4/icon3.png') no-repeat;
    background-size: 68px 57px;
    position: absolute;
    top: 27px;
    left: -106px;
  }
}
</style>
